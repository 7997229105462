import { TransactionStatus, Transaction, TransactionType } from '../types'; 


const useTransactionUtils = () => {
  const createTransaction = (status: TransactionStatus, id: number, fromTransaction: string, toTransaction: string, amount: string, type:TransactionType): Transaction => {
 
    return {
      id: id, 
      from: fromTransaction, 
      to: toTransaction,
      status: status,
      amount: amount,
      type: type,
    };
  };
  return {
    createTransaction
  };
};

export default useTransactionUtils;
