import useNetworkErrorStyles from './network-error.styles';
import { ReactComponent as SwitchNetwork } from '../../images/switch-network.svg';
import { ReactComponent as MetamaskIcon } from '../../images/metamask-fox.svg';
import { NETWORKS } from '../../chains';
import * as React from 'react';

interface NetworkButtonProps {
  isMetamask: boolean;
  switchChain: (chainId: string) => void;
  chainId: string;
}

const NetworkButton = ({ chainId, isMetamask, switchChain }: NetworkButtonProps) => {
  const classes = useNetworkErrorStyles();
  const name = NETWORKS[chainId].chainName;
  if (!isMetamask) {
    return <p className={classes.networkName}>{name}</p>;
  }
  return (
    <div className={classes.switchNetworkButton} onClick={() => switchChain(chainId)}>
      <MetamaskIcon width="20" height="20" style={{ marginRight: 5 }} />
      <b>{name}</b>
    </div>
  );
};

interface NetworkErrorProps {
  isMetamask: boolean;
  switchChain: (chainId: string) => void;
  chainId: string;
}


function NetworkError({ chainId, isMetamask, switchChain }: NetworkErrorProps) {
  const classes = useNetworkErrorStyles();

  return (
    <div className={classes.networkError}>
      <SwitchNetwork />
      <p className={classes.title}>Switch Network</p>
      <div className={classes.descriptionContainer}>
        <p className={classes.description}>Please, connect to</p>
        <NetworkButton chainId= {chainId} isMetamask={isMetamask} switchChain={switchChain} />
      </div>
    </div>
  );
}

export default NetworkError;
