import { useEffect, useState, useCallback } from 'react';
import useStepperStyles from './stepper.styles';
import useStep, { Step } from '../../hooks/use-stepper-data';
import Login from '../login/login.view';
import DepositForm from '../deposit-form/deposit-form.view';
import FinalizeDepositForm from '../finalize-deposit-form/finalize-deposit-form.view';
import FinalizeWithdrawForm from '../finalize-withdraw-form/finalize-withdraw-form.view';
import WithdrawForm from '../withdraw-form/withdraw-form.view';
import TxConfirm from '../tx-confirm/tx-confirm.view';
import TxConfirmChain from '../tx-confirm-chain/tx-confirm-chain.view';
import useDeposit from '../../hooks/use-deposit';
import useWithdraw from '../../hooks/use-withdraw';
import * as React from 'react';
import useFinalizeDeposit from '../../hooks/use-finalize-deposit';
import useFinalizeWithdraw from '../../hooks/use-finalize-withdraw';
import TxPendingOracleConfirmation from '../tx-pending-oracle-confirmation/tx-pending-oracle-confirmation.view';
import TxPendingDeposit from '../tx-pending-deposit/tx-pending-deposit.view';
import TxPendingWithdraw from '../tx-pending-withdraw/tx-pending-withdraw.view';
import TxConfirmAssertion from '../tx-confirm-assertion/tx-confirm-assertion.view';
import TxCreateAssertion from '../tx-create-assertion/tx-create-assertion.view';
import TxFinalizeDeposit from '../tx-finalize-deposit/tx-finalize-deposit.view';
import TxFinalizeWithdraw from '../tx-finalize-withdraw/tx-finalize-withdraw.view';
import TxOverview from '../tx-overview/tx-overview.view';
import TransactionList from '../tx-transaction/tx-transaction.view'
import { ethers } from 'ethers';
import {
  REACT_APP_L1_NETWORK_ID,
  REACT_APP_SPECULAR_NETWORK_ID,
  REACT_APP_L1_RPC_URL,
  REACT_APP_SPECULAR_RPC_URL
} from '../../constants';
import type { PendingDeposit, PendingWithdrawal } from '../../types';

interface StepperProps {
  wallet: any;
  loadWallet: () => void;
  disconnectWallet: () => void;
  isMetamask: boolean;
  switchChain: (args1: any) => void;
}

function Stepper({ wallet, loadWallet, disconnectWallet, isMetamask, switchChain }: StepperProps) {
  const classes = useStepperStyles();
  const { step, switchStep } = useStep();
  const { deposit, data: depositData, resetData: resetDepositData } = useDeposit();
  const { finalizeDeposit, data: finalizeDepositData } = useFinalizeDeposit(switchChain);
  const { withdraw, data: withdrawData, resetData: resetWithdrawData } = useWithdraw();
  const { finalizeWithdraw, data: finalizeWithdrawData } = useFinalizeWithdraw(switchChain);
  const [amount, setAmount] = useState('0');
  const [id, setId] = useState(0);

  const PENDINGDEPOSIT: PendingDeposit = {
    blockNumber: 0,
    proofL1BlockNumber: undefined,
    depositHash: '',
    depositTx: {
      version: 0,
      nonce: ethers.BigNumber.from('0'),
      sender: '',
      target: '',
      value: ethers.BigNumber.from('0'),
      gasLimit: ethers.BigNumber.from('0'),
      data: ''
    }
  };
  interface PendingData {
    status: string;
    data: PendingDeposit;
  }
  const INITIALPENDINGDEPOSIT = { status: 'pending', data: PENDINGDEPOSIT };
  const [pendingDeposit, setPendingDeposit] = useState<PendingData>(INITIALPENDINGDEPOSIT);

  interface PendingWithdrawlData {
    status: string;
    data: PendingWithdrawal;
  }
  const PENDINGWITHDRAW: PendingWithdrawal = {
    l2BlockNumber: 0,
    proofL2BlockNumber: undefined,
    assertionID: undefined,
    withdrawalHash: '',
    withdrawalTx: {
      version: 0,
      nonce: ethers.BigNumber.from('0'),
      sender: '',
      target: '',
      value: ethers.BigNumber.from('0'),
      gasLimit: ethers.BigNumber.from('0'),
      data: ''
    }
  };

  const INITIALPENDINGWITHDRAW = { status: 'pending', data: PENDINGWITHDRAW };
  const [pendingWithdraw, setPendingWithdraw] =
    useState<PendingWithdrawlData>(INITIALPENDINGWITHDRAW);
  const [isDeposit, setIsDeposit] = useState<boolean>(true);

  const tabs = [{ name: 'Deposit', step: Step.Deposit }];
  tabs.push({ name: 'Withdraw', step: Step.Withdraw });
  tabs.push({ name: 'Transaction', step: Step.Transaction });

  const [activeTab, setActiveTab] = useState(tabs[0].name);

  const selectTab = useCallback(
    (tab: { name: string; step: Step }) => {
      if (activeTab === tab.name) return;
      setActiveTab(tab.name);
      switchStep(tab.step);
    },
    [activeTab, switchStep]
  );

  useEffect(() => {
    if (step === Step.Deposit || step === Step.Withdraw || step === Step.Transaction) {
      localStorage.setItem('currentStep', step);
      setActiveTab(step);
    }
  }, [selectTab, step]);

  const l1Provider = new ethers.providers.StaticJsonRpcProvider(REACT_APP_L1_RPC_URL);
  const l2Provider = new ethers.providers.StaticJsonRpcProvider(REACT_APP_SPECULAR_RPC_URL);

  return (
    <div className={classes.container}>
      {![Step.Login].includes(step) && (
        <div className={classes.tabs}>
          {
          tabs.map((tab) => (
            <button
              key={tab.name}
              className={activeTab === tab.name ? classes.tabActive : classes.tab}
              onClick={() => selectTab(tab)}
              disabled={![Step.Withdraw, Step.Deposit, Step.Overview, Step.Transaction].includes(step)}
            >
              <span className={classes.tabName}>{tab.name}</span>
            </button>
          ))}
        </div>
      )}
      <div className={classes.stepper}>
        {(() => {
          switch (step) {
            case Step.Login: {
              let savedStep;
              if (wallet) {
                savedStep = localStorage.getItem('currentStep') as Step;
                if (!savedStep) {
                  savedStep = 'Withdraw' as Step;
                }
              } else {
                savedStep = Step.Login;
              }
              return (
                <Login
                  wallet={wallet}
                  onLoadWallet={loadWallet}
                  onGoToNextStep={() => switchStep(savedStep as Step)}
                />
              );
            }
            case Step.Deposit: {
              console.log("Deposit");
              switchChain(REACT_APP_L1_NETWORK_ID.toString());
              return (
                <DepositForm
                  wallet={wallet}
                  depositData={depositData}
                  onAmountChange={resetDepositData}
                  l1Provider={l1Provider}
                  l2Provider={l2Provider}
                  onSubmit={(fromAmount, selectedTokenKey) => {
                    deposit(wallet, fromAmount, selectedTokenKey);
                    setAmount(ethers.utils.formatUnits(fromAmount,18).toString());
                    setId(Math.floor(Math.random() * Number.MAX_SAFE_INTEGER));
                    switchStep(Step.ConfirmDeposit);
                  }}
                  onDisconnectWallet={disconnectWallet}
                  isMetamask={isMetamask}
                  switchChain={switchChain}
                />
              );
            }

            case Step.ConfirmDeposit: {
              console.log("ConfirmDeposit");
              return (
                <TxConfirm
                  wallet={wallet}
                  transactionData={depositData}
                  onGoBack={() => switchStep(Step.Deposit)}
                  onGoToNextStep={() => switchStep(Step.PendingDeposit)}
                />
              );
            }
            case Step.PendingDeposit: {
              console.log("PendingDeposit");
              return (
                <TxPendingDeposit
                  wallet={wallet}
                  depositData={depositData}
                  l1Provider={l1Provider}
                  amount={amount}
                  id={id}
                  pendingDeposit={pendingDeposit}
                  setPendingDeposit={setPendingDeposit}
                  onGoBack={() => switchStep(Step.Deposit)}
                  onGoToFinalizeStep={() => {
                    switchStep(Step.ConfirmOracle);
                  }}
                />
              );
            }
            case Step.ConfirmOracle: {
              console.log("confirm oracle");
              return (
                <TxPendingOracleConfirmation
                  wallet={wallet}
                  depositData={depositData}
                  pendingDeposit={pendingDeposit}
                  setPendingDeposit={setPendingDeposit}
                  switchChain={switchChain}
                  onGoToNextStep={() => switchStep(Step.FinalizeDepositForm)}
                />
              );
            }

            case Step.FinalizeDepositForm: {
              console.log("FinalizeDepositForm");
              return (
                <FinalizeDepositForm
                  wallet={wallet}
                  onSubmit={() => {
                    switchChain(REACT_APP_SPECULAR_NETWORK_ID.toString());
                    switchStep(Step.ConfirmDepositChain);
                  }}
                  onDisconnectWallet={disconnectWallet}
                />
              );
            }
            case Step.ConfirmDepositChain: {
              console.log("ConfirmDepositChain");
              return (
                <TxConfirmChain
                  wallet={wallet}
                  networkId={REACT_APP_SPECULAR_NETWORK_ID.toString()}
                  onGoBack={() => switchStep(Step.Deposit)}
                  onGoToNextStep={() => {
                    finalizeDeposit(wallet, amount, pendingDeposit, setPendingDeposit);
                    switchStep(Step.FinalizeDeposit);
                  }}
                />
              );
            }
            case Step.FinalizeDeposit: {
              return (
                <TxFinalizeDeposit
                  wallet={wallet}
                  amount={amount}
                  id={id}
                  depositData={depositData}
                  finalizeDepositData={finalizeDepositData}
                  onGoBack={() => switchStep(Step.Deposit)}
                  onGoToOverviewStep={() => switchStep(Step.Overview)}
                />
              );
            }

            case Step.Withdraw: {
              console.log("Withdraw");
              switchChain(REACT_APP_SPECULAR_NETWORK_ID.toString());
              return (
                <WithdrawForm
                  wallet={wallet}
                  withdrawData={withdrawData}
                  onAmountChange={resetWithdrawData}
                  l1Provider={l1Provider}
                  l2Provider={l2Provider}
                  onSubmit={(fromAmount, selectedTokenKey) => {
                    withdraw(wallet, fromAmount, selectedTokenKey);
                    setAmount(ethers.utils.formatUnits(fromAmount,18).toString());
                    setId(Math.floor(Math.random() * Number.MAX_SAFE_INTEGER));
                    switchStep(Step.ConfirmWithdraw);
                  }}
                  onDisconnectWallet={disconnectWallet}
                  isMetamask={isMetamask}
                  switchChain={switchChain}
                />
              );
            }

            case Step.ConfirmWithdraw: {
              console.log("ConfirmWithdraw");
              return (
                <TxConfirm
                  wallet={wallet}
                  transactionData={withdrawData}
                  onGoBack={() => switchStep(Step.Withdraw)}
                  onGoToNextStep={() => switchStep(Step.PendingWithdraw)}
                />
              );
            }
            case Step.PendingWithdraw: {
              console.log("PendingWithdraw");
              return (
                <TxPendingWithdraw
                  wallet={wallet}
                  withdrawData={withdrawData}
                  l2Provider={l2Provider}
                  amount={amount}
                  id={id}
                  pendingWithdraw={pendingWithdraw}
                  setPendingWithdraw={setPendingWithdraw}
                  onGoBack={() => switchStep(Step.Deposit)}
                  onGoToFinalizeStep={() => {
                    switchStep(Step.CreateAssertion);
                  }}
                />
              );
            }
            case Step.CreateAssertion: {
              console.log("CreateAssertion");
              return (
                <TxCreateAssertion
                  wallet={wallet}
                  withdrawData={withdrawData}
                  pendingWithdraw={pendingWithdraw}
                  setPendingWithdraw={setPendingWithdraw}
                  switchChain={switchChain}
                  onGoBack={() => switchStep(Step.Withdraw)}
                  onGoToNextStep={() => {
                    switchStep(Step.ConfirmAssertion);
                  }}
                />
              );
            }
            case Step.ConfirmAssertion: {
              console.log("ConfirmAssertion");
              return (
                <TxConfirmAssertion
                  wallet={wallet}
                  withdrawData={withdrawData}
                  pendingWithdraw={pendingWithdraw}
                  setPendingWithdraw={setPendingWithdraw}
                  switchChain={switchChain}
                  onGoBack={() => switchStep(Step.Withdraw)}
                  onGoToNextStep={() => {
                    switchStep(Step.FinalizeWithdrawForm);
                  }}
                />
              );
            }
            case Step.FinalizeWithdrawForm: {
              console.log("FinalizeWithdrawForm");
              return (
                <FinalizeWithdrawForm
                  wallet={wallet}
                  onSubmit={() => {
                    switchChain(REACT_APP_L1_NETWORK_ID.toString());
                    switchStep(Step.ConfirmWithdrawChain);
                  }}
                  onDisconnectWallet={disconnectWallet}
                />
              );
            }
            case Step.ConfirmWithdrawChain: {
              return (
                <TxConfirmChain
                  wallet={wallet}
                  networkId={REACT_APP_L1_NETWORK_ID.toString()}
                  onGoBack={() => switchStep(Step.Deposit)}
                  onGoToNextStep={() => {
                    setIsDeposit(false);
                    finalizeWithdraw(wallet, amount, pendingWithdraw.data);
                    switchStep(Step.FinalizeWithdrawl);
                  }}
                />
              );
            }
            case Step.FinalizeWithdrawl: {
              return (
                <TxFinalizeWithdraw
                  wallet={wallet}
                  amount={amount}
                  id={id}
                  withdrawData={withdrawData}
                  finalizeWithdrawData={finalizeWithdrawData}
                  onGoBack={() => switchStep(Step.Withdraw)}
                  onGoToOverviewStep={() => switchStep(Step.Overview)}
                />
              );
            }
            case Step.Overview: {
              return (
                <TxOverview
                  wallet={wallet}
                  finalizeTransactionData={finalizeDepositData}
                  transactionData={depositData}
                  onDisconnectWallet={disconnectWallet}
                  isDeposit={isDeposit}
                />
              );
            } 
            case Step.Transaction: {
              return (
                <TransactionList/>
              );
            }
            default: {
              return <></>;
            }
          }
        })()}
      </div>
    </div>
  );
}

export default Stepper;
