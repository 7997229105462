import { useState, useEffect } from 'react';
import {Transaction} from '../types'


// Custom hook to manage transactions in local cache
function useTransactionCache() {
  const [transactions, setTransactions] = useState<Transaction[]>([]);

  // Function to fetch transactions from local cache
  const fetchTransactions = (): Transaction[] => {
    const data = localStorage.getItem('transactions');
    return data ? JSON.parse(data) : [];
  };

  // Function to save transactions to local cache
  const saveTransactions = (transactions: Transaction[]) => {
    localStorage.setItem('transactions', JSON.stringify(transactions));
  };

  // Function to add or update a transaction in the cache
  const upsertTransaction = (transaction: Transaction) => {
    try{
        const index = transactions.findIndex((t) => t.id === transaction.id);
        const newTransactions = [...transactions];
        if (index !== -1) {
        // Update existing transaction
        newTransactions[index] = transaction;
        } else {
        // Add new transaction
        newTransactions.push(transaction);
        }
        setTransactions(newTransactions);
        saveTransactions(newTransactions);
    } catch (err) {
        console.log(err);
    }
  };

  // Load transactions from cache when the hook is used
  useEffect(() => {
    const loadedTransactions = fetchTransactions();
    setTransactions(loadedTransactions);
  }, []);

  return {
    transactions,
    upsertTransaction,
  };
}

export default useTransactionCache;
