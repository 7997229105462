import { useEffect } from 'react';
import Header from '../shared/header/header.view';
import useTxPendingStyles from './tx-pending-deposit.styles';
import LinkIcon from '@mui/icons-material/OpenInNew';
import Spinner from '../shared/spinner/spinner.view';
import { NETWORKS } from '../../chains';
import * as React from 'react';
import { REACT_APP_L1_NETWORK_ID } from '../../constants';
import type { PendingDeposit } from '../../types';
import useTransactionCache  from "../../hooks/use-transaction-cache";
import type { Data} from '../../types';
import  { TransactionStatus, TransactionType } from '../../types';
import useTransactionUtils from '../../hooks/use-transaction-utils'

interface PendingData {
  status: string;
  data: PendingDeposit;
}

interface TxPendingProps {
  wallet: {
    address: string;
    chainId: number;
    provider: any;
  };
  depositData: Data;
  l1Provider: any;
  amount: string;
  id: number;
  pendingDeposit: PendingData;
  setPendingDeposit: (args1: any) => void;
  onGoBack: () => void;
  onGoToFinalizeStep: () => void;
}

function TxPendingDeposit({
  wallet,
  depositData,
  l1Provider,
  amount,
  id,
  pendingDeposit,
  setPendingDeposit,
  onGoBack,
  onGoToFinalizeStep
}: TxPendingProps) {
  const classes = useTxPendingStyles();
  const { upsertTransaction } = useTransactionCache();
  const { createTransaction } = useTransactionUtils();

  useEffect(() => {
    console.log("depodepositData.data : ", depositData.data);
    if (depositData.status === 'failed') {
      const myTransaction = createTransaction(TransactionStatus.Failure,id,depositData.data.hash,"",amount,TransactionType.Deposit);
      upsertTransaction(myTransaction);
      onGoBack();
    }
    if (depositData.status === 'successful') {
      setPendingDeposit({ status: 'initiated', data: depositData.pendingDeposit });
      const myTransaction = createTransaction(TransactionStatus.Pending,id,depositData.data.hash,"",amount,TransactionType.Deposit);
      upsertTransaction(myTransaction);
      onGoToFinalizeStep();
    }
  }, [depositData, pendingDeposit, onGoBack, onGoToFinalizeStep, amount, createTransaction, id, upsertTransaction, setPendingDeposit]);

  return (
    <div className={classes.txOverview}>
      <Header address={wallet.address} title={`Specular Bridge`} />
      <div className={classes.spinnerWrapper}>
        <Spinner />
      </div>
      <div className={classes.buttonGroup}>
        <a
          className={classes.button}
          href={`${NETWORKS[wallet.chainId].blockExplorerUrl}/tx/${depositData?.data?.hash}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Check {NETWORKS[REACT_APP_L1_NETWORK_ID].name}'s transaction status here
          <LinkIcon className={classes.buttonIcon} />
        </a>
      </div>
      Proceeding to Finalize Transaction
    </div>
  );
}

export default TxPendingDeposit;
