import { createUseStyles } from 'react-jss';

interface Theme {
  spacing: {
    (value: number): string;
    unit: number;
  };
  fontWeights: {
    regular: number;
    bold: number;
    normal: number;
    medium: number;
    extraBold: number;
  };

  palette: {
    grey: {
      light1: string;
      light2: string;
      dark: string;
      main: string;
    };
    red: string;
    purple: string;
    primary: string;
    white: string;
    black: string;
  };
  buttonTransition: string;
}

const useHeaderStyles = createUseStyles((theme: Theme) => ({
  header: {
    width: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(4)
  },
  title: {
    fontSize: theme.spacing(3)
  },
  address: {
    marginTop: theme.spacing(2),
    fontSize: theme.spacing(2.5),
    color: theme.palette.grey.dark
  },
  balance: {
    marginTop: theme.spacing(1),
    fontSize: theme.spacing(2),
    color: theme.palette.black
  },
  goBackButton: {
    position: 'absolute',
    background: 'transparent',
    appearance: 'none',
    border: 'none',
    cursor: 'pointer',
    padding: theme.spacing(1),
    left: -theme.spacing(1),
    top: -theme.spacing(0.5),
    '&:disabled': {
      cursor: 'default'
    }
  },
  closeButton: {
    position: 'absolute',
    background: 'transparent',
    appearance: 'none',
    border: 'none',
    cursor: 'pointer',
    padding: theme.spacing(1),
    right: -theme.spacing(1),
    top: -theme.spacing(0.5)
  },
  disconnectButton: {
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
    textDecoration: 'underline',
    fontSize: theme.spacing(2),
    marginTop: theme.spacing(1.5)
  },
  button: {
    backgroundColor: theme.palette.black,
    color: '#ffffff',
    border: 'none',
    borderRadius: '4px',
    padding: '8px 16px',
    fontSize: '16px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.grey
    }
  },
  tabs: {
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    top: 40,
    left: '50%',
    transform: 'translateX(-50%)',
    padding: '100px 100px 20px',
    zIndex: 1
  },
  tab: {
    minWidth: '235px',
    height: '40px',
    padding: '0 20px',
    background: theme.palette.black,
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0px 0px 4px rgba(200, 200, 221, 0.53)',
    marginRight: '1px',
    cursor: 'pointer',
    border: 0,
    '&:disabled': {
      cursor: 'default'
    }
  },
  tabActive: {
    composes: '$tab',
    background: theme.palette.white
  },
  tabName: {
    whiteSpace: 'nowrap'
  },
  closeIcon: {
    width: 16
  }
}));

export default useHeaderStyles;
