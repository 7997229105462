import { useState } from 'react';

export enum Step {
  Loading = 'Loading',
  Login = 'Login',
  Deposit = 'Deposit',
  ConfirmDeposit = 'confirmDeposit',
  FinalizeDepositForm = 'finalizeDepositForm',
  ConfirmOracle = 'confirmOracle',
  ConfirmDepositChain = 'confirmDepositChain',
  ConfirmWithdrawChain = 'ConfirmWithdrawChain',
  PendingDeposit = 'pendingDeposit',
  Withdraw = 'Withdraw',
  ConfirmWithdraw = 'confirmWithdraw',
  PendingWithdraw = 'pendingWithdraw',
  ConfirmAssertion = 'confirmAssertion',
  BatchAppend = 'batchAppend',
  CreateAssertion = 'createAssertion',
  FinalizeWithdrawForm = 'finalizeWithdrawForm',
  FinalizeDeposit = 'finalizeDeposit',
  FinalizeWithdrawl = 'finalizeWithdrawl',
  Overview = 'overview',
  Transaction='Transaction'
}

interface StepperData {
  step: Step;
  switchStep: (step: Step) => void;
}

function useStepperData(): StepperData {
  const [step, setStep] = useState<Step>(Step.Login);

  const switchStep = (step: Step): void => {
    setStep(step);
  };

  return { step, switchStep };
}

export default useStepperData;
