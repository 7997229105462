import { useEffect } from 'react';
import Header from '../shared/header/header.view';
import useTxPendingStyles from './tx-create-assertion.styles';
import LinkIcon from '@mui/icons-material/OpenInNew';
import Spinner from '../shared/spinner/spinner.view';
import { NETWORKS } from '../../chains';
import * as React from 'react';
import { ethers } from 'ethers';
import { REACT_APP_L1_RPC_URL, REACT_APP_ROLLUP_ADDRESS, REACT_APP_SPECULAR_NETWORK_ID } from '../../constants';
import { IRollup__factory } from '@specularl2/sdk';
import type { PendingWithdrawal } from '../../types';

interface PendingWithdrawlData {
  status: string;
  data: PendingWithdrawal;
}
interface TxPendingProps {
  wallet: {
    address: string;
    chainId: number;
    provider: any;
  };
  withdrawData: {
    status: string;
    data?: {
      hash: string;
    };
  };
  pendingWithdraw: PendingWithdrawlData;
  setPendingWithdraw: (args1: any) => void;
  onGoToNextStep: () => void;
  onGoBack: () => void;
  switchChain: (args1: any) => void;
}

function TxCreateAssertion({
  wallet,
  withdrawData,
  pendingWithdraw,
  setPendingWithdraw,
  switchChain,
  onGoBack,
  onGoToNextStep
}: TxPendingProps) {
  const classes = useTxPendingStyles();
  const l1Provider = new ethers.providers.StaticJsonRpcProvider(REACT_APP_L1_RPC_URL);
  const rollup = IRollup__factory.connect(REACT_APP_ROLLUP_ADDRESS, l1Provider);

  useEffect(() => {
    rollup.on(rollup.filters.AssertionCreated(), async (assertionID, asserter, vmHash, event) => {
      console.log('AssertionCreated', assertionID.toString());
      if (pendingWithdraw) {
        if (pendingWithdraw.data?.assertionID !== undefined) {
          // We already know which assertion this withdrawal is included in
          return;
        }
        pendingWithdraw.data.assertionID = assertionID;
        setPendingWithdraw(pendingWithdraw);
        onGoToNextStep();
      }
    });
  }, [pendingWithdraw, rollup, withdrawData, rollup.filters, onGoToNextStep, setPendingWithdraw]);
  return (
    <div className={classes.txOverview}>
      <Header address={wallet.address} title={`Specular Bridge`} />
      <div className={classes.spinnerWrapper}>
        <Spinner />
      </div>
      <div className={classes.buttonGroup}>
        <a
          className={classes.button}
          href={`${NETWORKS[REACT_APP_SPECULAR_NETWORK_ID].blockExplorerUrl}/tx/${withdrawData?.data?.hash}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Transaction on {NETWORKS[REACT_APP_SPECULAR_NETWORK_ID].name} is successful. Check transaction
          details here
          <LinkIcon className={classes.buttonIcon} />
        </a>
      </div>
      Waiting for Assertion Creation
    </div>
  );
}

export default TxCreateAssertion;
