import { ethers } from 'ethers';

export const REACT_APP_L1_NETWORK_ID = parseInt(process.env.REACT_APP_L1_NETWORK_ID || '0');
export const REACT_APP_L1_RPC_URL = process.env.REACT_APP_L1_RPC_URL || '';
export const REACT_APP_SPECULAR_NETWORK_ID = parseInt(process.env.REACT_APP_SPECULAR_NETWORK_ID || '0');
export const REACT_APP_SPECULAR_RPC_URL = process.env.REACT_APP_SPECULAR_RPC_URL || '';
export const REACT_APP_L1PORTAL_ADDRESS = process.env.REACT_APP_L1PORTAL_ADDRESS || '';
export const REACT_APP_L2PORTAL_ADDRESS = process.env.REACT_APP_L2PORTAL_ADDRESS || '';
export const REACT_APP_L1ORACLE_ADDRESS = process.env.REACT_APP_L1ORACLE_ADDRESS || '';
export const REACT_APP_ROLLUP_ADDRESS = process.env.REACT_APP_ROLLUP_ADDRESS || '';
export const REACT_APP_L1_EXPLORER_URL = process.env.REACT_APP_L1_EXPLORER_URL || '';
export const REACT_APP_SPECULAR_EXPLORER_URL = process.env.REACT_APP_SPECULAR_EXPLORER_URL || '';
export const REACT_APP_BRIDGE_GAS_LIMIT = parseInt(process.env.REACT_APP_BRIDGE_GAS_LIMIT || '0');
export const REACT_APP_DEPOSIT_BALANCE_THRESHOLD = ethers.utils.parseEther(process.env.REACT_APP_DEPOSIT_BALANCE_THRESHOLD || '0');
export const REACT_APP_RELAYER_ENDPOINT = process.env.REACT_APP_RELAYER_ENDPOINT || '';
export const REACT_APP_L1_BRIDGE_ADDR = process.env.REACT_APP_L1_BRIDGE_ADDR || '';
export const REACT_APP_L2_BRIDGE_ADDR = process.env.REACT_APP_L2_BRIDGE_ADDR || '';
