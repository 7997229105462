import type { ethers, BigNumber } from 'ethers';

export type Data = {
  status: string;
  error?: string;
  data?: ethers.providers.TransactionResponse;
  blockNumber?: number;
  proofL1BlockNumber?: number;
  crossDomainMessage?: CrossDomainMessage;
  stateRoot?: string;
  pendingWithdrawal?: PendingWithdrawal;
  pendingDeposit?: PendingDeposit;
};

export enum TransactionStatus {
  Success = 'success',
  Failure = 'failure',
  Pending = 'pending',
}

export enum TransactionType {
  Deposit = 'deposit',
  Withdraw = 'withdraw',
}

export type Transaction = {
  id: number;
  from: string;
  to: string;
  status: TransactionStatus;
  amount: string;
  type: TransactionType;
};

export type FinalizeData = {
  status: string;
  error?: string;
  data?: string;
};

export type CrossDomainMessage = {
  version: number;
  nonce: any;
  sender: any;
  target: any;
  value: any;
  gasLimit: any;
  data: any;
};

export type PendingDeposit = {
  blockNumber: number;
  proofL1BlockNumber: number | undefined;
  depositHash: string;
  depositTx: CrossDomainMessage;
};

export type PendingWithdrawal = {
  l2BlockNumber: number;
  proofL2BlockNumber: number | undefined;
  assertionID: BigNumber | undefined;
  withdrawalHash: string;
  withdrawalTx: CrossDomainMessage;
};

export type MessageProof = {
  accountProof: string[];
  storageProof: string[];
};

export type wallet = {
  address: string;
  chainId: number;
  provider: any;
};

export type RawBlock = {
  l2BlockHash: string;
  l2StateRoot: string;
};
