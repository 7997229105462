import { createUseStyles } from 'react-jss';

interface DepositFormStylesProps {
  error: boolean;
}
interface Theme {
  spacing: {
    (value: number): string;
    unit: number;
  };
  fontWeights: {
    regular: number;
    bold: number;
    normal: number;
    medium: number;
    extraBold: number;
  };

  palette: {
    grey: {
      light1: string;
      light2: string;
      dark: string;
      main: string;
    };
    red: string;
    purple: string;
    primary: string;
    white: string;
  };
  buttonTransition: string;
}

const useDepositFormStyles = createUseStyles((theme: Theme) => ({
  depositForm: {
    flex: 1
  },
  form: {
    display: 'flex',
    flexDirection: 'column'
  },
  card: ({ error }: DepositFormStylesProps) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    border: `1px solid ${error ? theme.palette.red : theme.palette.grey.light2}`,
    borderRadius: `${theme.spacing(2.5)}px`
  }),
  cardTitleText: {
    fontSize: theme.spacing(2),
    fontWeight: theme.fontWeights.normal
  },
  titleText: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    fontSize: theme.spacing(2),
    fontWeight: theme.fontWeights.normal
  },
  fromInput: {
    fontSize: theme.spacing(2.5),
    appearance: 'none',
    border: 'none',
    outline: 'none',
    width: '100%',
    textAlign: 'center',
    caretColor: theme.palette.grey.light1,
    '&:disabled': {
      background: theme.palette.white
    }
  },
  toValue: {
    fontSize: theme.spacing(2),
    color: theme.palette.grey.main,
    textAlign: 'center',
    width: '100%',
    marginTop: theme.spacing(4),
    borderTop: `1px solid ${theme.palette.grey.light2}`,
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`
  },
  inputErrorContainer: {
    fontSize: theme.spacing(2),
    fontWeight: theme.fontWeights.medium,
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.red,
    marginTop: theme.spacing(2)
  },
  cardErrorIcon: {
    marginRight: theme.spacing(1),
    minWidth: theme.spacing(2),
    '& path': {
      fill: theme.palette.red
    }
  },
  cardIcon: {
    marginTop: theme.spacing(1),
    alignSelf: 'center',
    maxWidth: theme.spacing(10),
    maxHeight: theme.spacing(10)
  },
  submitButton: {
    fontSize: theme.spacing(2),
    margin: `${theme.spacing(5)}px auto ${theme.spacing(1)}px`,
    padding: `${theme.spacing(2)}px 0`,
    background: theme.palette.primary,
    color: theme.palette.white,
    width: '40%',
    borderRadius: theme.spacing(2),
    appearance: 'none',
    border: 'none',
    transition: theme.buttonTransition,
    cursor: 'pointer',
    '&:disabled': {
      background: theme.palette.grey.light1,
      cursor: 'default'
    }
  },
  depositError: {
    fontSize: theme.spacing(2),
    fontWeight: theme.fontWeights.medium,
    color: theme.palette.red,
    textAlign: 'center',
    marginTop: theme.spacing(4)
  }
}));

export default useDepositFormStyles;
