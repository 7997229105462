import React from 'react';
import useTransactionCache from '../../hooks/use-transaction-cache';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import { grey } from '@mui/material/colors';
import { NETWORKS } from '../../chains';
import { REACT_APP_L1_NETWORK_ID, REACT_APP_SPECULAR_NETWORK_ID } from '../../constants';

const MyTransactions = () => {
  const { transactions } = useTransactionCache();

  // Function to return a Button with a color based on the status
  const StatusButton = ({ status}) => {
    let color;
    switch (status.toLowerCase()) {
      case 'successful':
        color = 'success';
        break;
      case 'failure':
        color = 'error';
        break;
      case 'pending':
        color = 'warning';
        break;
      default:
        color = 'default';
        break;
    }

    return (
      <Button variant="contained" color={color}>
        {status.charAt(0).toUpperCase() + status.slice(1)}
      </Button>
    );
  };
  const L1Button = ({ status, from, to }) => {
    const isDeposit = status.toLowerCase() === 'deposit';
    const buttonLink = isDeposit ? to : from;
    return (
      <Button
      component="a"
      href={`${NETWORKS[REACT_APP_L1_NETWORK_ID].blockExplorerUrl}/tx/${buttonLink}`}
      target="_blank"  
      variant="contained" 
      rel="noopener noreferrer" 
      style={{ backgroundColor: grey[100], color: '#000', textTransform: 'none', }}>
        {"View Transaction on L1"}
      </Button>
    );
  };

  const L2Button = ({ status, from, to }) => {
    const isDeposit = status.toLowerCase() !== 'deposit';
    const buttonLink = isDeposit ? to : from;
    return (
      <Button 
      component="a"
      href={`${NETWORKS[REACT_APP_SPECULAR_NETWORK_ID].blockExplorerUrl}/tx/${buttonLink}`}
      target="_blank" 
      variant="contained" 
      rel="noopener noreferrer"
      style={{ backgroundColor: grey[100], color: '#000', textTransform: 'none', }}>
        {"View Transaction on Specular"}
      </Button>
    );
  };

  return (
    <div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>L1 Transaction</TableCell>
              <TableCell>Specular Transaction</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Type</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions.map((transaction) => (
              <TableRow
                key={transaction.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <L1Button status={transaction.status} from={transaction.from} to={transaction.to} />
                </TableCell>
                <TableCell>
                  <L2Button status={transaction.status} from={transaction.from} to={transaction.to} />
                </TableCell>
                <TableCell>
                  <StatusButton status={transaction.status} />
                </TableCell>
                <TableCell>{transaction.amount.toString()}</TableCell>
                <TableCell>{transaction.type}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default MyTransactions;
