import { useEffect, useState, useCallback } from 'react';
import Header from '../shared/header/header.view';
import useTxPendingStyles from './tx-pending-oracle-confirmation.styles';
import LinkIcon from '@mui/icons-material/OpenInNew';
import Spinner from '../shared/spinner/spinner.view';
import { NETWORKS } from '../../chains';
import * as React from 'react';
import { ethers } from 'ethers';
import { REACT_APP_L1_NETWORK_ID, REACT_APP_L1ORACLE_ADDRESS, REACT_APP_SPECULAR_RPC_URL } from '../../constants';
import { L1Oracle__factory } from '@specularl2/sdk';
import type { PendingDeposit } from '../../types';
import type { Data } from '../../types';

interface PendingData {
  status: string;
  data: PendingDeposit;
}
interface TxPendingProps {
  wallet: {
    address: string;
    chainId: number;
    provider: any;
  };
  depositData: Data;
  pendingDeposit: PendingData;
  setPendingDeposit: (args1: any) => void;
  onGoToNextStep: () => void;
  switchChain: (args1: any) => void;
}

function TxPendingOracleConfirmation({
  wallet,
  depositData,
  pendingDeposit,
  setPendingDeposit,
  switchChain,
  onGoToNextStep
}: TxPendingProps) {
  const classes = useTxPendingStyles();
  const l2Provider = new ethers.providers.StaticJsonRpcProvider(REACT_APP_SPECULAR_RPC_URL);
  const l1Oracle = L1Oracle__factory.connect(REACT_APP_L1ORACLE_ADDRESS, l2Provider);
  console.log("depositData.blockNumber is: ",depositData.blockNumber);
  const [isStateRoot, setsStateRoot] = useState(false);

  const checkStateRoot = useCallback(async () => {
    const oracleBlockNumber = await l1Oracle.number();
    console.log("oracleBlockNumber is: ", oracleBlockNumber.toNumber());
    console.log("pendingDeposit.status is: ", pendingDeposit.status);

    if (oracleBlockNumber.toNumber() >= depositData.blockNumber && pendingDeposit.status === 'initiated') {
      console.log('nextStep')
      pendingDeposit.data.proofL1BlockNumber = depositData.blockNumber;
      setPendingDeposit(pendingDeposit);
      setsStateRoot(true);
      onGoToNextStep();
    }
  }, [l1Oracle, depositData.blockNumber, pendingDeposit, setPendingDeposit, onGoToNextStep]); // include all dependencies here

  useEffect(() => {
    if (!isStateRoot) {
      const interval = setInterval(() => {
        checkStateRoot();
      }, 10000); // 10000 milliseconds = 10 seconds

      // Clean up the interval on component unmount
      return () => clearInterval(interval);
    }
  }, [isStateRoot, checkStateRoot]); // now includes checkStateRoot


  return (
    <div className={classes.txOverview}>
      <Header address={wallet.address} title={`Specular Bridge`} />
      <div className={classes.spinnerWrapper}>
        <Spinner />
      </div>
      <div className={classes.buttonGroup}>
        <a
          className={classes.button}
          href={`${NETWORKS[REACT_APP_L1_NETWORK_ID].blockExplorerUrl}/tx/${depositData?.data?.hash}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Transaction on {NETWORKS[REACT_APP_L1_NETWORK_ID].name} is successful. Check transaction details
          here
          <LinkIcon className={classes.buttonIcon} />
        </a>
      </div>
      Waiting for confirmation from Oracle.
    </div>
  );
}

export default TxPendingOracleConfirmation;
