import { useEffect } from 'react';
import Header from '../shared/header/header.view';
import useTxPendingStyles from './tx-pending-withdraw.styles';
import LinkIcon from '@mui/icons-material/OpenInNew';
import Spinner from '../shared/spinner/spinner.view';
import { NETWORKS } from '../../chains';
import * as React from 'react';
import { REACT_APP_SPECULAR_NETWORK_ID } from '../../constants';
import type { PendingWithdrawal } from '../../types';
import useTransactionCache  from "../../hooks/use-transaction-cache";
import type { Data} from '../../types';
import  { TransactionStatus, TransactionType } from '../../types';
import useTransactionUtils from '../../hooks/use-transaction-utils'

interface PendingWithdrawlData {
  status: string;
  data: PendingWithdrawal;
}

interface TxPendingProps {
  wallet: {
    address: string;
    chainId: number;
    provider: any;
  };
  withdrawData: Data;
  l2Provider: any;
  amount: string;
  id: number;
  pendingWithdraw: PendingWithdrawlData;
  setPendingWithdraw: (args1: any) => void;
  onGoBack: () => void;
  onGoToFinalizeStep: () => void;
}

function TxPendingWithdraw({
  wallet,
  withdrawData,
  l2Provider,
  amount,
  id,
  pendingWithdraw,
  setPendingWithdraw,
  onGoBack,
  onGoToFinalizeStep
}: TxPendingProps) {
  const classes = useTxPendingStyles();
  const { upsertTransaction } = useTransactionCache();
  const { createTransaction } = useTransactionUtils();

  useEffect(() => {
    if (withdrawData.status === 'failed') {
      const myTransaction = createTransaction(TransactionStatus.Failure,id,withdrawData.data.hash,"",amount,TransactionType.Withdraw);
      upsertTransaction(myTransaction);
      onGoBack();
    }
    if (withdrawData.status === 'successful') {
      setPendingWithdraw({ status: 'initiated', data: withdrawData.pendingWithdrawal });
      const myTransaction = createTransaction(TransactionStatus.Pending,id,withdrawData.data.hash,"",amount,TransactionType.Withdraw);
      upsertTransaction(myTransaction);
      onGoToFinalizeStep();
    }
  }, [withdrawData, pendingWithdraw, onGoBack, onGoToFinalizeStep, amount, createTransaction, withdrawData.data.hash, id, upsertTransaction, setPendingWithdraw]);


  return (
    <div className={classes.txOverview}>
      <Header address={wallet.address} title={`Specular Bridge`} />
      <div className={classes.spinnerWrapper}>
        <Spinner />
      </div>
      <div className={classes.buttonGroup}>
        <a
          className={classes.button}
          href={`${NETWORKS[wallet.chainId].blockExplorerUrl}/tx/${withdrawData?.data?.hash}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Check {NETWORKS[REACT_APP_SPECULAR_NETWORK_ID].name}'s transaction status here
          <LinkIcon className={classes.buttonIcon} />
        </a>
      </div>
      Proceeding to Finalize Transaction
    </div>
  );
}

export default TxPendingWithdraw;
