import { useState } from 'react';
import { BigNumber, ethers } from 'ethers';

import { REACT_APP_L2_BRIDGE_ADDR, REACT_APP_L2PORTAL_ADDRESS } from '../constants';
import { TOKEN, erc20Abi } from '../tokens';

import { L2Portal__factory, L2StandardBridge__factory } from '@specularl2/sdk';
import type { PendingWithdrawal } from '../types';
import type { Data } from '../types';

interface wallet {
  address: string;
  chainId: number;
  provider: any;
}

const INITIAL_DATA: Data = { status: 'pending' };

function useWithdraw() {
  const [data, setData] = useState<Data>(INITIAL_DATA);

  const withdraw = async (
    wallet: wallet,
    amount: BigNumber,
    selectedTokenKey: number
  ): Promise<void> => {
    console.log('From wallet ' + wallet.address + ' Amount ' + amount);
    setData({ status: 'loading' });

    if (!wallet) {
      setData({ status: 'failed', error: "Wallet doesn't exist" });
      return;
    }

    try {
      // Select token based on the provided key
      const selectedToken = TOKEN[selectedTokenKey];
      const signer = await (wallet.provider as any).getSigner();
      const l2StandardBridge = L2StandardBridge__factory.connect(REACT_APP_L2_BRIDGE_ADDR, signer);
      const l2Portal = L2Portal__factory.connect(REACT_APP_L2PORTAL_ADDRESS, signer);

      let tx;
      // Check if the selected token is native ETH or an ERC20 token
      if (selectedToken.l1TokenContract === '') {
        tx = await l2StandardBridge.bridgeETH(200_000, [] as any, {
          value: amount
        });
      } else {
        console.log('erc20');
        const l2Token = new ethers.Contract(selectedToken.l2TokenContract, erc20Abi, signer);
        // Approve the bridge to spend the tokens
        const approveTx = await l2Token.approve(REACT_APP_L2_BRIDGE_ADDR, amount);
        await approveTx.wait();

        tx = await l2StandardBridge.bridgeERC20(
          selectedToken.l2TokenContract,
          selectedToken.l1TokenContract,
          amount,
          200_000,
          [] as any
        );
      }

      console.log(tx);
      setData({ status: 'pending', data: tx });
      const txWithLogs =  await tx.wait();
      const withdrawTxBlockNum = txWithLogs.blockNumber;
      // Parse the withdraw event from the transaction logs
      const withdrawEvent = l2Portal.interface.parseLog(txWithLogs.logs[1]);
      // Create a new pending withdrawal object
      const newPendingWithdrawal: PendingWithdrawal = {
        l2BlockNumber: withdrawTxBlockNum,
        proofL2BlockNumber: undefined,
        assertionID: undefined,
        withdrawalHash:withdrawEvent.args.withdrawalHash,
        withdrawalTx: {
          version:0,
          nonce:withdrawEvent.args.nonce,
          sender:withdrawEvent.args.sender,
          target:withdrawEvent.args.target,
          value:withdrawEvent.args.value,
          gasLimit:withdrawEvent.args.gasLimit,
          data:withdrawEvent.args.data,
        }
      }

      setData({ status: 'successful', data: tx, blockNumber: withdrawTxBlockNum, pendingWithdrawal: newPendingWithdrawal });

    await l2Portal.initiatedWithdrawals(tx.hash)
    } catch (errorCatched) {
      const err: any = errorCatched;
      let error = 'Transaction failed.';
      if (err.code === -32603) {
        error = 'Transaction was not sent because of the low gas price. Try to increase it.';
      }
      setData({ status: 'failed', error });
      console.log(err);
    }
  };

  const resetData = () => {
    setData(INITIAL_DATA);
  };

  return { withdraw, data, resetData };
}

export default useWithdraw;
