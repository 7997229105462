import useAppStyles from './app.styles';
import Stepper from './stepper/stepper.view';
import Footer from './footer/footer.view';
import Header from './header/header.view';
import FAQ from './faq/faq.view';
import { useState } from 'react';
import useWallet from '../hooks/use-wallet';

function App() {
  const classes = useAppStyles();
  const [setOpenGetMoreFaq] = useState(() => () => null);
  const { wallet, loadWallet, disconnectWallet, isMetamask, switchChain } = useWallet();

  return (
    <div className={classes.container}>
      <Header
        wallet={wallet}
        loadWallet={loadWallet}
        disconnectWallet={disconnectWallet}
        isMetamask={isMetamask}
        switchChain={switchChain}
      />
      <Stepper
        wallet={wallet}
        loadWallet={loadWallet}
        disconnectWallet={disconnectWallet}
        isMetamask={isMetamask}
        switchChain={switchChain}
      />
      <FAQ setOpenGetMoreFaq={setOpenGetMoreFaq} />
      <Footer />
    </div>
  );
}

export default App;
