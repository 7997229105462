import { createUseStyles } from 'react-jss';

interface Theme {
  spacing: {
    (value: number): string;
    unit: number;
  };
  fontWeights: {
    regular: number;
    bold: number;
    normal: number;
    medium: number;
    extraBold: number;
  };

  palette: {
    grey: {
      light1: string;
      light2: string;
      dark: string;
      main: string;
    };
    red: string;
    purple: string;
    primary: string;
    white: string;
  };
  buttonTransition: string;
}

const useHeaderStyles = createUseStyles((theme: Theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: '10px',
    justifyContent: 'space-between',
    flexDirection: 'column',
    width: '100%',
    position: 'absolute',
    zIndex: 2
  },

  rowBetween: {
    alignItems: 'flex-start',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%', // Make sure to span the entire width of the parent
    padding: '1rem 1rem 0 1rem'
  },

  headerElement: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start' // Everything is left-aligned within the element
  },

  headerControls: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end' // Align the controls to the right
  },

  uniIcon: {
    marginLeft: 'auto' // Push the icon to the right end
  },
  logo: {
    marginRight: '0px'
  },
  chainTextWrapper: {
    marginRight: '20px',
    whiteSpace: 'nowrap',
    width: 'fit-content', 
    color: '#f3841e', 
    background: '#f3841e0d',
    borderRadius: '12px'
  },
  networkCard: {
    borderRadius: '12px',
    padding: '8px 12px',
    backgroundColor: theme.palette.grey 
  },
  addressTextWrapper: {
    marginRight: '20px',
    whiteSpace: 'nowrap',
    width: 'fit-content', 
    color: theme.palette.primary, 
    background: '#f3841e0d',
    borderRadius: '12px'
  },
  addressCard: {
    borderRadius: '12px',
    padding: '8px 12px',
    backgroundColor: theme.palette.grey // Assuming you add yellow to your theme
  },
  accountElement: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: '12px',
    whiteSpace: 'nowrap',
    width: '100%'
  },
  accountActive: {
    backgroundColor: theme.palette.grey.main
  },
  balanceText: {
    flexShrink: 0,
    paddingLeft: '0.75rem',
    paddingRight: '0.5rem',
    fontWeight: 500
  },
  headerElementWrap: {
    display: 'flex',
    alignItems: 'center'
  },
  button: {
    backgroundColor: theme.palette.primary,
    color: '#ffffff',
    border: 'none',
    borderRadius: '12px',
    padding: '12px 20px',
    fontSize: '16px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.purple
    }
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  titleText: {
    alignItems: 'bottom',
    justifyContent: 'bottom',
    padding: '20px 7px 0'
  }
}));

export default useHeaderStyles;
