import {
  REACT_APP_L1_EXPLORER_URL,
  REACT_APP_L1_RPC_URL,
  REACT_APP_SPECULAR_EXPLORER_URL,
  REACT_APP_SPECULAR_RPC_URL,
  REACT_APP_BRIDGE_GAS_LIMIT
} from './constants';

interface NativeCurrency {
  name: string;
  symbol: string;
  decimals: number;
}

interface Network {
  forkVersion: string;
  name: string;
  symbol: string;
  chainName: string;
  rpcUrl: string;
  blockExplorerUrl: string;
  nativeCurrency: NativeCurrency;
  chainId: string; // Add the chainId property here
}

const NETWORKS: Record<string, Network> = {
  '1337': {
    forkVersion: '0000006f',
    name: 'L1',
    symbol: 'XDAI',
    chainName: 'L1 Testnet',
    rpcUrl: REACT_APP_L1_RPC_URL,
    blockExplorerUrl: REACT_APP_L1_EXPLORER_URL,
    nativeCurrency: {
      name: 'xDAI',
      symbol: 'xDAI',
      decimals: 18
    },
    chainId: '1337' // Add the chainId value here
  },
  '13527': {
    forkVersion: '00000064',
    name: 'Specular Devnet',
    symbol: 'ETH',
    chainName: 'Specular Devnet',
    rpcUrl: REACT_APP_SPECULAR_RPC_URL,
    blockExplorerUrl: REACT_APP_SPECULAR_EXPLORER_URL,
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18
    },
    chainId: '13527' // Add the chainId value here
  },
  '10200': {
    forkVersion: '0000006f',
    name: 'L1',
    symbol: 'XDAI',
    chainName: 'L1 Testnet',
    rpcUrl: REACT_APP_L1_RPC_URL,
    blockExplorerUrl: REACT_APP_L1_EXPLORER_URL,
    nativeCurrency: {
      name: 'xDAI',
      symbol: 'xDAI',
      decimals: 18
    },
    chainId: '10200' // Add the chainId value here
  },
  '93481': {
    forkVersion: '00000064',
    name: 'Specular Devnet',
    symbol: 'ETH',
    chainName: 'Specular Devnet',
    rpcUrl: REACT_APP_SPECULAR_RPC_URL,
    blockExplorerUrl: REACT_APP_SPECULAR_EXPLORER_URL,
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18
    },
    chainId: '93481' // Add the chainId value here
  }
};

export { REACT_APP_BRIDGE_GAS_LIMIT, NETWORKS };
