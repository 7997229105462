import useHeaderStyles from './header.styles';
import * as React from 'react';

interface HeaderProps {
  address?: string;
  title: string;
  onDisconnectWallet?: () => void;
}

function Header({ address, title, onDisconnectWallet }: HeaderProps): JSX.Element {
  const classes = useHeaderStyles();

  return (
    <div className={classes.header}>
    </div>
  );
}

export default Header;
