import { useState } from 'react';
import { ethers } from 'ethers';
import { getStorageKey } from '../utils';
import type { PendingWithdrawal, MessageProof, RawBlock } from '../types';
import { IL1Portal__factory } from '@specularl2/sdk';
import type { FinalizeData } from '../types';
import {
  REACT_APP_L1_NETWORK_ID,
  REACT_APP_SPECULAR_RPC_URL,
  REACT_APP_L1PORTAL_ADDRESS,
  REACT_APP_L2PORTAL_ADDRESS
} from '../constants';

interface wallet {
  address: string;
  chainId: number;
  provider: any;
}

const INITIAL_DATA: FinalizeData = { status: 'pending' };

// Function to generate a withdrawal proof
async function generateWithdrawProof(withdrawal: PendingWithdrawal): Promise<MessageProof> {
  console.log('generating proof');
  const l2Provider = new ethers.providers.StaticJsonRpcProvider(REACT_APP_SPECULAR_RPC_URL);
  const rawProof = await l2Provider.send('eth_getProof', [
    REACT_APP_L2PORTAL_ADDRESS,
    [getStorageKey(withdrawal.withdrawalHash)],
    ethers.utils.hexlify(withdrawal.proofL2BlockNumber)
  ]);
  return {
    accountProof: rawProof.accountProof,
    storageProof: rawProof.storageProof[0].proof
  };
}
// Function to generate the raw block data
async function generateRawBlock(withdrawal: PendingWithdrawal): Promise<RawBlock> {
  console.log('generating Raw Blocks');
  const l2Provider = new ethers.providers.StaticJsonRpcProvider(REACT_APP_SPECULAR_RPC_URL);
  const rawProof =await l2Provider.send("eth_getBlockByNumber", [
    ethers.utils.hexValue(withdrawal.proofL2BlockNumber),
    false, // We only want the block header
  ]);
  return {
    l2BlockHash: rawProof.hash,
    l2StateRoot: rawProof.stateRoot
  };
}

type SwitchChainFunction = (arg: string) => void;

function useFinalizeWithdraw(switchChain: SwitchChainFunction) {
  const [data, setData] = useState<FinalizeData>(INITIAL_DATA);

  // Function to handle the finalization of withdrawals
  const finalizeWithdraw = async (
    wallet: wallet,
    amount: ethers.BigNumberish,
    pendingWithdraw: PendingWithdrawal
  ): Promise<void> => {
    if (!wallet) {
      setData({ status: 'failed', error: "Wallet doesn't exist" });
      return;
    }

    setData({ status: 'loading' });

    const provider = await wallet.provider;
    const signer = await provider.getSigner();

    const l1Portal = IL1Portal__factory.connect(REACT_APP_L1PORTAL_ADDRESS, signer);
    try {
      const rawBlock = await generateRawBlock(pendingWithdraw);
      const proof = await generateWithdrawProof(pendingWithdraw);
      console.log(proof);
      console.log(pendingWithdraw);

      if (pendingWithdraw.assertionID) {
        // Finalize the withdrawal transaction on L1
        const tx = await l1Portal.finalizeWithdrawalTransaction(
          pendingWithdraw.withdrawalTx,
          pendingWithdraw.assertionID,
          rawBlock.l2BlockHash,
          rawBlock.l2StateRoot,
          proof.accountProof,
          proof.storageProof
        );
        setData({ status: 'pending', data: tx.hash });
        await tx.wait();
        setData({ status: 'successful', data: tx.hash });
      } else {
        throw console.error('assertionID not found');
      }
    } catch (e) {
      console.error(e);
    }
    switchChain(REACT_APP_L1_NETWORK_ID.toString());
  };

  return { finalizeWithdraw, data };
}

export default useFinalizeWithdraw;
