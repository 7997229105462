import { useState } from 'react';
import { BigNumber, ethers } from 'ethers';
import type { PendingDeposit } from '../types';
import { REACT_APP_L1_BRIDGE_ADDR, REACT_APP_L1PORTAL_ADDRESS, REACT_APP_L1_RPC_URL } from '../constants';
import { TOKEN, erc20Abi } from '../tokens';
import { IL1Portal__factory } from '@specularl2/sdk';
import { L1StandardBridge__factory } from '@specularl2/sdk';
import type { Data } from '../types';

interface wallet {
  address: string;
  chainId: number;
  provider: any;
}

const INITIAL_DATA: Data = { status: 'waiting' };

function useDeposit() {
  const [data, setData] = useState<Data>(INITIAL_DATA);

  // Function to handle the deposit action
  const deposit = async (
    wallet: wallet,
    amount: BigNumber,
    selectedTokenKey: number
  ): Promise<void> => {
    console.log('From wallet ' + wallet.address + ' Amount ' + amount);

    if (!wallet) {
      setData({ status: 'failed', error: "Wallet doesn't exist" });
      return;
    }

    try {
      // Select token based on the provided key
      const selectedToken = TOKEN[selectedTokenKey];
      const signer = await (wallet.provider as any).getSigner();
      const l1StandardBridge = L1StandardBridge__factory.connect(REACT_APP_L1_BRIDGE_ADDR, signer);
      const l1Provider = new ethers.providers.StaticJsonRpcProvider(REACT_APP_L1_RPC_URL);

      // Check if the selected token is native ETH or an ERC20 token
      let tx;
      if (selectedToken.l1TokenContract === '') {

        tx = await l1StandardBridge.bridgeETH(200_000, [] as any, {
          value: amount
        });
      } else {
        console.log('erc20');
        const l1Token = new ethers.Contract(selectedToken.l1TokenContract, erc20Abi, signer);
        // Approve the bridge to spend the tokens
        const approveTx = await l1Token.approve(REACT_APP_L1_BRIDGE_ADDR, amount);
        await approveTx.wait();

        tx = await l1StandardBridge.bridgeERC20(
          selectedToken.l1TokenContract,
          selectedToken.l2TokenContract,
          amount,
          20_000_000,
          [] as any
        );
      }

      setData({ status: 'pending', data: tx });
      const txWithLogs = await tx.wait();
      const l1Portal = IL1Portal__factory.connect(REACT_APP_L1PORTAL_ADDRESS, l1Provider);
      // Parse the deposit event from the transaction logs
      const depositEvent = l1Portal.interface.parseLog(txWithLogs.logs[1]);
      // Retrieve the current block number and its state root
      let blockNumber = await l1Provider.getBlockNumber();
      let rawBlock = await l1Provider.send("eth_getBlockByNumber", [
        ethers.utils.hexValue(blockNumber),
        false, // We only want the block header
      ]);
      let stateRoot = l1Provider.formatter.hash(rawBlock.stateRoot);
      // Create a new pending deposit object
      const newPendingDeposit: PendingDeposit = {
        blockNumber: blockNumber,
        proofL1BlockNumber: undefined,
        depositHash: tx.hash,
        depositTx: {
          version: 0,
          nonce: depositEvent.args.nonce,
          sender: depositEvent.args.sender,
          target: depositEvent.args.target,
          value: depositEvent.args.value,
          gasLimit: depositEvent.args.gasLimit,
          data: depositEvent.args.data,
        }
      };
      console.log("txWithLogs :", txWithLogs);
      setData({ status: 'successful', data: tx, blockNumber: blockNumber, stateRoot:stateRoot, pendingDeposit:newPendingDeposit });
    } catch (errorCatched) {
      const err: any = errorCatched;
      let error = 'Transaction failed.';
      if (err.code === -32603) {
        error = 'Transaction was not sent because of the low gas price. Try to increase it.';
      }
      setData({ status: 'failed', error });
      console.log(err);
    }
  };

  const resetData = () => {
    setData(INITIAL_DATA);
  };

  return { deposit, data, resetData };
}

export default useDeposit;
