import { useEffect } from 'react';
import Header from '../shared/header/header.view';
import useTxFinalizeDepositStyles from './tx-finalize-deposit.styles';
import LinkIcon from '@mui/icons-material/OpenInNew';
import Spinner from '../shared/spinner/spinner.view';
import { NETWORKS } from '../../chains';
import { REACT_APP_L1_NETWORK_ID, REACT_APP_SPECULAR_NETWORK_ID } from '../../constants';
import  { TransactionStatus, TransactionType } from '../../types';
import useTransactionUtils from '../../hooks/use-transaction-utils'
import useTransactionCache  from "../../hooks/use-transaction-cache";

interface TxFinalizeDepositProps {
  wallet: {
    address: string;
    chainId: number;
    provider: any;
  };
  amount: string;
  id: number;
  depositData: {
    status: string;
    data?: {
      hash: string;
    };
  };
  finalizeDepositData: {
    status: string;
    data?: string;
  };
  onGoBack: () => void;
  onGoToOverviewStep: () => void;
}

function TxFinalizeDeposit({
  wallet,
  amount,
  id,
  depositData,
  finalizeDepositData,
  onGoBack,
  onGoToOverviewStep
}: TxFinalizeDepositProps) {
  const classes = useTxFinalizeDepositStyles();
  const { upsertTransaction } = useTransactionCache();
  const { createTransaction } = useTransactionUtils();

  useEffect(() => {
    if (finalizeDepositData.status === 'failed') {
      const myTransaction = createTransaction(TransactionStatus.Failure,id,depositData.data.hash,finalizeDepositData.data,amount,TransactionType.Deposit);
      upsertTransaction(myTransaction);
      onGoBack();
    }
    console.log(finalizeDepositData.data);
    if (finalizeDepositData.status === 'successful') {
      const myTransaction = createTransaction(TransactionStatus.Success,id,depositData.data.hash,finalizeDepositData.data,amount,TransactionType.Deposit);
      upsertTransaction(myTransaction);
      onGoToOverviewStep();
    }
  }, [finalizeDepositData, onGoBack, onGoToOverviewStep, amount, createTransaction, depositData.data.hash, id, upsertTransaction]);

  return (
    <div className={classes.txOverview}>
      <Header address={wallet.address} title={`Specular Bridge`} />
      <div className={classes.buttonGroup}>
        <a
          className={classes.button}
          href={`${NETWORKS[REACT_APP_L1_NETWORK_ID].blockExplorerUrl}/tx/${depositData?.data?.hash}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Check successful transaction details on {NETWORKS[REACT_APP_L1_NETWORK_ID].name} for deposit
          here
          <LinkIcon className={classes.buttonIcon} />
        </a>
      </div>
      <div className={classes.spinnerWrapper}>
        <Spinner />
      </div>
      <div className={classes.buttonGroup}>
        <a
          className={classes.button}
          href={`${NETWORKS[REACT_APP_SPECULAR_NETWORK_ID].blockExplorerUrl}/tx/${finalizeDepositData.data}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Check transaction finalization status here
          <LinkIcon className={classes.buttonIcon} />
        </a>
      </div>
    </div>
  );
}

export default TxFinalizeDeposit;
