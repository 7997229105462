import { createUseStyles } from 'react-jss';

const useStepperStyles = createUseStyles((theme: any) => ({
  stepper: {
    minWidth: 525,
    minHeight: 562.5,
    background: theme.palette.white,
    borderRadius: theme.spacing(3.75),
    boxShadow: '0px 0px 4px rgba(200, 200, 221, 0.53)',
    padding: `80px ${theme.spacing(3.5)}px`,
    paddingBottom: theme.spacing(7),
    display: 'flex',
    flexDirection: 'column',
    zIndex: 1,
    position: 'relative'
  },
  container: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '100px 0 20px',
  },
  tabs: {
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    top: 40,
    left: '50%',
    transform: 'translateX(-50%)',
    padding: '100px 100px 20px',
    zIndex: 2
  },
  tab: {
    minWidth: '150px',
    height: '40px',
    padding: '0 20px',
    color: theme.palette.grey.light1,
    background: theme.palette.white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '10px', // Increase this value to add more spacing between tabs
    cursor: 'pointer',
    border: 0,
    '&:disabled': {
      cursor: 'default'
    }
  },
  tabActive: {
    composes: '$tab',
    color: theme.palette.black,
  },
  tabName: {
    whiteSpace: 'nowrap'
  }
}));

export default useStepperStyles;
