import React from 'react';
import useHeaderStyles from './header.styles';
import Logo from '../../images/logo.svg';
import { NETWORKS } from 'chains';

interface HeaderProps {
  wallet: any;
  loadWallet: () => void;
  disconnectWallet: () => void;
  isMetamask: boolean;
  switchChain: (args1: any) => void;
}

function Header({ wallet, loadWallet, disconnectWallet, isMetamask, switchChain }: HeaderProps) {
  const classes = useHeaderStyles();

  function getPartiallyHiddenEthereumAddress(ethereumAddress: string): string {
    const firstAddressSlice = ethereumAddress.slice(0, 6);
    const secondAddressSlice = ethereumAddress.slice(
      ethereumAddress.length - 4,
      ethereumAddress.length
    );

    return `${firstAddressSlice} *** ${secondAddressSlice}`;
  }

  return (
    <div className={classes.header}>
      <div className={classes.rowBetween}>
        <div className={classes.headerElement}>
          <a href="." className={classes.title}>
            <div className={classes.uniIcon}>
              <img src={Logo} alt="Specular Bridge" className={classes.logo} />
            </div>
          </a>
        </div>
        <div className={classes.headerControls}>
          <div className={classes.headerElement}>
            <div className={classes.chainTextWrapper}>
              {wallet && NETWORKS[wallet.chainId] ? (
                <div className={classes.networkCard}>{NETWORKS[wallet.chainId].chainName}</div>
              ) : null}
            </div>
            <div className={classes.addressTextWrapper}>
              {wallet && NETWORKS[wallet.chainId] ? (
                <div className={classes.addressCard}>{getPartiallyHiddenEthereumAddress(wallet.address)}</div>
              ) : null}
            </div>
            <div className={`${classes.accountElement} ${wallet ? classes.accountActive : ''}`}>
              {wallet && (
                <button className={classes.button} onClick={disconnectWallet}>
                  Disconnect
                </button>
              )}
              {!wallet && (
                <button className={classes.button} onClick={loadWallet}>
                  Connect
                </button>
              )}
            </div>
          </div>
          <div className={classes.headerElementWrap}>
            {/* Add your VersionSwitch, Settings, and Menu components here */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
